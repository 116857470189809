import { Button } from "@mui/material";
import React from "react";

const Cardd=({
    image,
    orderID,
    itemName,
    amountPaid,
    purchasePrice,
    Seller_Name,
    paidTime,
    orderStatus,
    rrrLink,
    itemID,
}) => { 

       return(
        <div className="card" >
            <div className="info-order" style={{fontSize:"20px",backgroundColor:orderStatus.includes("Completed")?"blue":orderStatus.includes("Active")?"orange":"red",textAlign:"center",color:"white",padding:"10px 10px",display:'flex',flexDirection:"row",flexWrap:"wrap",justifyContent:"space-between"}}>
                <span>Order ID: {orderID}</span>
                <span>{paidTime}</span>
            </div>
            <div className="card-content">
                <div style={{flexGrow:4}} className="card-image">
                  <img src={image} height={"400px"} width={"100%"} alt="Image Not Available" className="card-img"/>
                </div>
                <div style={{flexGrow:8,marginLeft:8}} className="card-info">
                    <div style={{flexGrow:1,fontSize:"25px"}}>
                        <strong>Item Name:</strong>{itemName}
                    </div>
                    <table style={{flexGrow:2}}>
                        <tbody>
                            <tr>
                                <td>Amount Paid: </td>
                                <td>€{amountPaid}</td>
                            </tr>
                            {purchasePrice.includes("N/A")?<></>:
                            <tr>
                                <td>Purchase Price: </td>
                                <td>{`€`+purchasePrice.split(" ")[0]}</td>
                            </tr>
                            }
                            {Seller_Name=="" || !Seller_Name?<></>:
                            <tr>
                                <td>Seller Name: </td>
                                <td>{Seller_Name}</td>
                            </tr>
                            }
                            <tr>
                                <td>Order Status: </td>
                                <td>{orderStatus}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{flexGrow:6}}>

                    </div>
                    <div className="info-btn" style={{flexGrow:1}}>
                        <Button variant="contained" sx={{padding:"20px 40px",fontSize:"15px"}}  href={rrrLink.includes("N/A")?"":`${rrrLink}`} target="_blank">RRR link</Button>
                        <Button variant="contained" sx={{padding:"20px 40px",fontSize:"15px"}} href={`https://ebay.com/itm/${itemID}`} target="_blank">Ebay Link</Button>
                    </div>
                </div>
            </div>

        </div>
       )
};
export default Cardd;