import React from 'react'
import './App.css';
import {Route,Routes} from 'react-router-dom';
import GetOrders from './Components/GetOrders';
import GetProducts from './Components/GetProducts';
import Change from './Components/Change';
import Import from './Components/Import';
import Sold from './Components/Sold';
import Login from './Components/Login';
import Card from './Components/Card';

function App() {
  return (
    <React.Fragment>
        <Routes>
          <Route exact path="/" Component={Login}/>
          <Route exact path="/login" Component={Login}/>
          <Route exact path="/card" Component={Card}/>
          <Route exact path="/change" Component={Change}/>
          <Route exact path="/import" Component={Import}/>
          <Route exact path="/sold" Component={Sold}/>
          <Route exact path="/orders" Component={GetOrders}/>
          <Route exact path="/products" Component={GetProducts}/>
        </Routes>
    </React.Fragment>    
  );
}

export default App;
