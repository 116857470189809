import React,{useEffect,useState} from 'react';
import Header from './OpenHeader';
import Footer from './Footer';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import axios from 'axios';
import Spinner from './Spinner';
import Snackbar from '@mui/material/Snackbar';
import {useNavigate} from 'react-router-dom';
import {domain} from '../domain';
import Card from './ProductCard'; 
const defaultTheme = createTheme();
axios.defaults.withCredentials = true;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

function GetProducts() {

    const navigate = useNavigate()

    const [data,setdata] = useState([{
        "ItemID":"",
        "Title":"",
        "BuyItNowPrice":{"#text":""},
        "Quantity":"",
        "ListingDetails" : {"StartTime":""}
        // "SellerProfiles":{"SellerShippingProfile":{"ShippingProfileName":""}},
    }]);

    const [popopen, setpopopen] = useState(false);

    const [ItemID, setItemID] = useState("");

    const [dataloding,setdataloading] = useState(false)

    const handleClickOpen = (ID) => {
      setpopopen(true);
      setItemID(ID)
    };

    const handleClickClose = () => {
      setpopopen(false);
      setItemID("")
    };

    const [Backdropopen, setBackdropopen] = useState(true);
    const [auth, setauth] = useState(false);
    const [state, setState] = useState({
      open: false,
      vertical: 'top',
      horizontal: 'center',
      message:""
    });
    const { vertical, horizontal, open, message } = state;

    const [Page, setPage] = useState(1);
    const [Total, setTotal] = useState(0);
  
    const handleClose = () => {
      setState({ ...state, open: false, message:"" });
    };

    const [Message, setMessage] = useState("");

    const Remove = () => {
      handleClickClose()
      setBackdropopen(true)
      axios.get(`${domain}api/removeitem?ID=${ItemID}`).then((data)=>{
        setBackdropopen(false)
        setMessage(data.data.Message)
        setState({ vertical: 'top',horizontal: 'center', open: true,message:"Item Removed Successfully" });
      }).catch((err)=>{
        setBackdropopen(false)
        setState({ vertical: 'top',horizontal: 'center', open: true,message:"Something Went Wrong!" });
      });
    }

    const fetchPurchasePrices = async (Data) => {
      setBackdropopen(true)
      const updatedData = await Promise.all(Data.map(async (item) => {
          if (item.ItemID) {
              try {
                  const response = await axios.get(`${domain}api/purchaseprice?ID=${item.ItemID}`);
                  item.Purchase_Price = response.data.Item_Price;
                  item.Item_URL = response.data.Item_URL;
                  item.Seller_Name = response.data.Seller_Name;
                  item.Item_Image = response.data.RRR_mages;
              } catch (error) {
                  console.error('Error fetching purchase price:', error);
              }
          }
          return item;
      }));
      setdataloading(true)
      setBackdropopen(false)
      setdata(updatedData);
  };

    const next = (Page) => {
      setBackdropopen(true)
      axios.get(`${domain}api/getproducts?page=${Page+1}`).then((data)=>{
        fetchPurchasePrices(data.data.Products);
        setPage(Page+1)
        setBackdropopen(false)
      }).catch((err)=>{
        setBackdropopen(false)
        setState({ vertical: 'top',horizontal: 'center', open: true,message:"Something Went Wrong!" });
      });
    }
    const pre = (Page) => {
      if(Page !==1){
        setBackdropopen(true)
        axios.get(`${domain}api/getproducts?page=${Page-1}`).then((data)=>{
          fetchPurchasePrices(data.data.Products);
          setPage(Page-1)
          setBackdropopen(false)
        }).catch((err)=>{
          setBackdropopen(false)
          setState({ vertical: 'top',horizontal: 'center', open: true,message:"Something Went Wrong!" });
        });
      }
    }
  
    const Submit = (event) => {
      event.preventDefault();
      let data = new FormData(event.currentTarget);
      setBackdropopen(true)
      axios.get(`${domain}api/getitem?id=${data.get("ID")}`).then((data)=>{
        let single_item = [{
          "ItemID":data.data.Item['ItemID'],
          "ListingDetails":{"StartTime":data.data.Item['ListingDetails']['StartTime']},
          "Title":data.data.Item['Title'],
          "BuyItNowPrice":{"#text":data.data.Item['SellingStatus']['CurrentPrice']['#text']},
          "Quantity":data.data.Item['Quantity'],
        }]
        fetchPurchasePrices(single_item)
        setBackdropopen(false)
      }).catch((err)=>{
        setBackdropopen(false)
        setState({ vertical: 'top',horizontal: 'center', open: true,message:"No Product Returned" });
      });
    }

    useEffect(()=>{
      setBackdropopen(true)
      axios.get(`${domain}api/check`).then((data)=>{
      setauth(data.data.Auth)
        if (!data.data.Auth){
          setBackdropopen(false)
            navigate("/login")
        }
        else{
          axios.get(`${domain}api/getproducts?page=${Page}`).then((data)=>{
            setBackdropopen(false)
            fetchPurchasePrices(data.data.Products);
            setTotal(parseInt(data.data.Total)) 
        });
        }
    }).catch((err)=>{
      setBackdropopen(false)
      setState({ vertical: 'top',horizontal: 'center', open: true,message:"Something Went Wrong!" });
    });
    },[Message])

  return (
    <ThemeProvider theme={defaultTheme}>
        <Header/>
        <Spinner open={Backdropopen} />
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message={message}
          key={vertical + horizontal}
        />
        {auth ?
        <Container sx={{mb:12,mt:2}} style={{position:"relative"}} fixed>
      <Dialog
        open={popopen}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this item?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Clicl Yes to Delete OR Click No to Cancel
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose}>No</Button>
          <Button onClick={Remove} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
              <Box sx={{ flexGrow: 1}}>
                <Box component="form" onSubmit={Submit} noValidate sx={{ mt: 1 }}>
                  <Grid container spacing={1}>
                  <Grid item xs={3}>
                      <Item>
                        <TextField
                          id="outlined-select-currency"
                          label="Item ID"
                          name="ID"
                          sx={{width:"100%"}}
                        />
                      </Item>
                  </Grid>
                  <Grid item xs={2}>
                      <Item>
                        <Button type='submit' sx={{width:"100%",padding:"20px 10px"}} variant="contained" color="primary">Search</Button>
                      </Item>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {dataloding?
            <>
            <p><strong>Total Items : {Total}</strong></p>
            {data.map((element,index)=>(
            <Card
                    key={index}
                    image={element.Item_Image?element.Item_Image.split(",")[0]:"https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"}
                    itemName={element["Title"]}
                    Seller_Name={element["Seller_Name"]}
                    Ebay_Price={element['BuyItNowPrice']['#text']}
                    purchasePrice={element["Purchase_Price"]}
                    ListingTime={element["ListingDetails"]["StartTime"]?element["ListingDetails"]["StartTime"]:""}
                    rrrLink={element['Item_URL']=="#" || element['Item_URL']}
                    Quantity={element["Quantity"]}
                    itemID={element["ItemID"]}
                    handleClickOpen={handleClickOpen}
            />))}
            </>
            :<></>}
            {/* <TableContainer component={Paper} style={{overflowY:"scroll",height:"600px"}}>
                <Table id="table" sx={{ maxWidth: 100 }} aria-label="customized table" stickyHeader>
                <TableHead>
                    <TableRow>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">Sr#</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">Item ID</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="left">Item Name</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="left">Seller Name</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="left">Item Link</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="left">Price on RRR</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">Price on Ebay</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">Quantity</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">Created</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">Remove Item</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((element,index)=>(
                    <StyledTableRow key={index}>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} component="th" scope="row">
                            {((Page-1)*100)+(index+1)}
                        </StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">{element['ItemID']}</StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="left">{element["Title"]}</StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="left">{element["Seller_Name"]}</StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="left">{element['Item_URL']=="N/A"?"N/A":<a href={element['Item_URL']} target='_blank'>Link</a>}</StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">{element['Purchase_Price']}</StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">{element['BuyItNowPrice']['#text']}</StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">{element["Quantity"]}</StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">{element["ListingDetails"]["StartTime"]}</StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="center"><Button onClick={()=>handleClickOpen(element['ItemID'])} variant="contained" color="error">Remove</Button></StyledTableCell>
                    </StyledTableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer> */}
            <Box
            style={{marginTop:"1%"}}
              sx={{
                display: 'flex',
                flexDirection: 'rows',
                alignItems: 'center',
              }}
            >
            <Button disabled={Page===1?true:false} onClick={()=>{pre(Page)}} style={{padding:"15px 30px",marginBottom:"2%"}} variant="contained" color="primary">Previous</Button>
            <Button disabled={data.length<100?true:false} onClick={()=>{next(Page)}} style={{padding:"15px 50px",marginBottom:"2%",marginLeft:"1%"}} variant="contained" color="primary">Next</Button>
            </Box>
        </Container>:<></>}
        <Footer/>
    </ThemeProvider>
  )
}

export default GetProducts