import React from "react";
import Card from './OrderCard'; 
import '../App.css';
const App = ()=>{
return(
<div>
    <Card
          image="https://media.istockphoto.com/id/120600093/photo/car-engine.webp?s=1024x1024&w=is&k=20&c=pwXIuTcsROVDjay024D2Nd7awIE5SfHDlS5Vi5he3Mk=" 
          orderID=""
          itemName="BMW 5 E60 E61 Sonstige Steuergeräte / Module 9118729 61753 532256822"
          amountPaid="€"
          purchasePrice="€"
          paidTime=""
          orderStatus="Completed"
          rrrLink="https://example.com/rrr-link"  
          itemID="Item-123456"
    />
</div>
);
};
 export default App;